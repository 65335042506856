<template>
   <div>
	  <section id="client-balance">
		 <b-row>
			<b-col lg="3">
			   <!-- Filters -->
			   <b-card-actions action-collapse
							   title="Φίλτρα">

				  <p>{{ $t('reports.select-show') }}</p>
				  <b-button block
							class="mt-2"
							variant="outline-primary"
							@click="generateReport">
					 <span>{{ $t("generic.view-results") }}</span>
				  </b-button>
			   </b-card-actions>
			   <!-- END: Filters -->
			</b-col>

			<b-col lg="9">
			   <!--Πίνακας αποτελεσμάτων -->
			   <b-card no-body>
				  <b-row v-if="showPdf"
						 style="height: 100vh">
					 <vue-pdf-app :pdf="$store.getters.getBalance"
								  class="w-100 h-100" />
				  </b-row>
			   </b-card>
			   <!--Πίνακας αποτελεσμάτων -->
			</b-col>
		 </b-row>
	  </section>
   </div>
</template>

<script>
import {BButton, BButtonGroup, BButtonToolbar, BCard, BCardText, BCol, BFormGroup, BRow, BTable,} from "bootstrap-vue";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import caldoSettings from "@/caldoSettings";


export default {
   components: {
	  BRow,
	  BCol,
	  BCard,
	  BCardText,
	  BFormGroup,
	  vSelect,
	  flatPickr,
	  BButton,
	  BButtonToolbar,
	  BButtonGroup,
	  BCardActions,
	  BTable,
   },
   data() {
	  return {
		 showPdf: null,
	  };
   },
   methods: {
	  reset(e) {
		 e.preventDefault();
		 this.resetField(this.form);
	  },
	  async generateReport() {
		 await this.$store.dispatch('fetchPdfBalance');
		 this.showPdf = true;
	  }
   },
   created() {
	  caldoSettings.removeBreadcrumb();
   }
};
</script>


<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>